import React from 'react';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';
import { hideModal } from 'features/redux/actions/settings';
import { BackIcon, CloseIcon, Heading, HorizontalLine, ModalTopBarWrapper } from './shared';

interface Props {
  heading: string | any;
  onBackClick?: (() => void) | null;
}
function ModalTopBar({ heading, hideModal: localHide, onBackClick = null }: Props & PropsFromRedux) {
  return (
    <Wrapper>
      <ModalTopBarWrapper>
        {onBackClick ? (
          <BackIcon onClick={onBackClick} className="icon-action-back" />
        ) : (
          <CloseIcon onClick={() => localHide()} className="icon-close" />
        )}
        <Heading data-testid="modal-top-bar-heading">{heading}</Heading>
      </ModalTopBarWrapper>
      <HorizontalLine />
    </Wrapper>
  );
}

const mapDispatchToProps = {
  hideModal,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: white;
  z-index: 99999;
`;
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ModalTopBar);
